@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-2xl text-gray-800 dark:text-gray-100 tracking-wide font-semibold;
    }
    h2 {
        @apply text-4xl text-gray-900 dark:text-white font-semibold;
    }
    h3 {
        @apply text-3xl text-gray-900 dark:text-white font-semibold;
    }
    h4 {
        @apply text-xl text-gray-800 dark:text-white font-semibold;
    }
    h5 {
        @apply text-lg text-gray-800 dark:text-white font-semibold;
    }
}

input[type=file]::-webkit-file-upload-button {
    @apply text-gray-700 bg-gray-200 hover:bg-gray-300 font-medium text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
}

@media (prefers-color-scheme: dark) {
    input[type=file]::-webkit-file-upload-button {
        @apply text-gray-300 bg-gray-900 hover:bg-gray-800 font-medium text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
    }
}

.steven-jambon {
    height: 13px;
    margin-left: 4px;
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
}
